/**
 * Class representing a ProXCalculator.
 * @extends Calculator
 */
class ContactUsFormStepper extends FormStepper {
  constructor(formWrap, stringElementNames, floatElementNames) {

    if (!formWrap) {
      return [false, 'Need element wrapping form'];
    }
    
    const form = document.getElementById('contact-form');

    const firstName = document.getElementById('firstNamegen');
    const lastName = document.getElementById('lastNamegen');
    const email = document.getElementById('emailgen');
    const company = document.getElementById('companygen');
    const country = document.getElementById('country');
    const message = document.getElementById('message');
    const contactMeCheck = document.getElementById('contactMeCheck');


    const formInputs = {
      firstName,
      lastName,
      email,
      company,
      country,
      message,
      contactMeCheck
    };

    super(form, formInputs, stringElementNames, floatElementNames);

    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.company = company;
    this.country = country;
    this.message = message;

    if (this.form === null) {
      return [false, 'Cannot find form.'];
    }
  }

  validatePageInputs() {

    if (this.currentPageElement !== null) {
      // this.currentPageElement at this point needs to be the form
      const contactUsValidate = new FormStepperValidate(this.currentPageElement);
      return contactUsValidate.validateInput();
    }

    return true;
  }
}

new ContactUsFormStepper(document.getElementById('contact-form'), ['firstNamegen', 'lastNamegen', 'emailgen', 'companygen', 'country', 'message'], []);